<template>
  <div id="cloud-foods-layout">

    <cloud-header title="Τρόφιμα" subtitle="Εξερευνήστε τρόφιμα που έχουν δημιουργηθεί κατά την προσθήκη συνταγών, γευμάτων"></cloud-header>

    <div class="filters">

      <div class="row">
        <div class="col-12 col-md-4">
          <b-form-input v-model="query.search" placeholder="Αναζητήστε με βάση το όνομα..." debounce="400"/>
        </div>
        <div class="col-12 col-md-4">
          <b-form-select v-model="query.order" :options="orderings" class="mb-3">
          </b-form-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="dp in listings" :key="dp.id" class="col-12 col-md-6">
        <cloud-listing :item="dp"/>
      </div>
    </div>


    <ul v-if="initialLoadFinished" class="pagination pagination-rounded mb-0 float-right">
      <!-- pagination -->
      <b-pagination v-model="page" :total-rows="query.totalRows" :per-page="query.perPage" @input="fetchCloudFoods"></b-pagination>
    </ul>
  </div>
</template>


<script>
import CloudHeader from "@components/cloud/CloudHeader";
import CloudListing from "@components/cloud/CloudListing";
import {mapState} from "vuex";

export default{

  computed:{
    ...mapState({
      foodQuery: state => state.cloud._foodQuery
    })
  },
  components: {CloudHeader, CloudListing},
  data(){
    return{
      query:{
        search: null,
        perPage: 10,
        totalRows: 0,
        order: 'latest'
      },
      showTagList: false,
      page: 1,
      listings: [],
      orderings: [
        {value: 'latest', text: 'Πιο πρόσφατα'},
        {value:'oldest', text: 'Πιο παλιά'}, {value:'downloads', text: 'Περισσότερα downloads'}, {value:'likes',text: 'Περισσότερα likes'}],
      initialLoadFinished: false
    }
  },

  watch:{
    query:{
      handler(val){
        if(!this.initialLoadFinished) return;
        this.page = 1;
        this.$store.dispatch('cloud/setFoodQuery', {...this.query, page: this.page});
        this.fetchCloudFoods()
      },
      deep: true
    },
    page(val){
      this.$store.dispatch('cloud/setFoodQuery', {...this.query, page: val});
    }
  },
  mounted(){
    if(this.foodQuery){
      this.query = {...this.foodQuery}
      this.page = this.foodQuery.page
    }
    this.fetchCloudFoods();

  },
  methods:{
    fetchCloudFoods(){

      const query = {
        limit: this.query.perPage,
        offset: (this.page - 1) * this.query.perPage,
        type: 'foods'
      };



      if(this.query.tagIds) query.tags = this.query.tagIds;
      if(this.query.search) query.search = this.query.search;
      if(this.query.order) query.order = this.query.order;

      this.$axios.get('/cloud/listings', {params:query}).then(res => {
        this.query.totalRows = res.data.count;
        this.listings = res.data.rows;
        this.initialLoadFinished = true;
      }).catch(e=>{
        console.log(e);
      });

    }
  }
}
</script>
